<template>
  <div class="box">
    <!-- tabs选择栏 -->
    <div>
      <van-sticky>
        <van-tabs v-model="active"
          color="#ffd42f">
          <van-tab v-for="item in typeList"
            :key="item.Coding">
            <template #title>
              <div class="tabTitle"
                @click="typeClick(item.Coding)">
                <img :src="item.img">
                <div>{{ item.Name }}</div>
              </div>
            </template>
          </van-tab>
        </van-tabs>
      </van-sticky>
    </div>
    <!-- 商品列表 -->
    <div class="lists">
      <div class="listBox">
        <div class="list"
          v-for="item in list"
          :key="item.PromotionId"
          @click="detailClick(item.PromotionId)">
          <img :src="item.PosterUrl" />
          <div class="listRight">
            <div>{{item.Title}} <br /> <span style="font-size: 12px;font-weight: 400;">{{item.ShopName}}</span> </div>
            <div class="titleC">
              <!-- <img src="../../assets/shop/shopa.png">
              <span style="font-size:16px;color:#F09B0B">{{ item.Level }}</span> -->
              <img src="../../assets/shop/shopb.png">
              <span>{{item.StartDate}} ~ {{item.EndDate}}</span>
            </div>
            <div class="titleC">
              <img src="../../assets/shop/shopc.png">
              <span>{{ item.OrgName }}</span>
            </div>
          </div>
        </div>
        <van-empty :image-size="[30, 20]"
          description="暂无商铺信息~"
          v-if="!list.length" />
        <!-- 商品轮播 -->
        <!-- <div class="shopbox">
                    <van-swipe :loop="false" :width="110" show-indicators="false">
                        <van-swipe-item>
                            <div class="goodsList">
                                <img src="../../assets/shop/shop2.jpg">
                                <div>锐澳 8度苹果西打风味鸡尾酒 500ml/罐</div>
                                <div><span style="font-size: 12px;">¥</span><span style="padding-left: 3px;">2.5</span>
                                </div>
                            </div>
                        </van-swipe-item>
                        <van-swipe-item>
                            <div class="goodsList">
                                <img src="../../assets/shop/shop2.jpg">
                                <div>锐澳 8度苹果西打风味鸡尾酒 500ml/罐</div>
                                <div><span style="font-size: 12px;">¥</span><span style="padding-left: 3px;">2.5</span>
                                </div>
                            </div>
                        </van-swipe-item>
                        <van-swipe-item>
                            <div class="goodsList">
                                <img src="../../assets/shop/shop2.jpg">
                                <div>锐澳 8度苹果西打风味鸡尾酒 500ml/罐</div>
                                <div><span style="font-size: 12px;">¥</span><span style="padding-left: 3px;">2.5</span>
                                </div>
                            </div>
                        </van-swipe-item>
                        <van-swipe-item>
                            <div class="goodsList">
                                <img src="../../assets/shop/shop2.jpg">
                                <div>锐澳 8度苹果西打风味鸡尾酒 500ml/罐</div>
                                <div><span style="font-size: 12px;">¥</span><span style="padding-left: 3px;">2.5</span>
                                </div>
                            </div>
                        </van-swipe-item>
                        <van-swipe-item>
                            <div class="goodsList">
                                <img src="../../assets/shop/shop2.jpg">
                                <div>锐澳 8度苹果西打风味鸡尾酒 500ml/罐</div>
                                <div><span style="font-size: 12px;">¥</span><span style="padding-left: 3px;">2.5</span>
                                </div>
                            </div>
                        </van-swipe-item>
                        <van-swipe-item>
                            <div class="goodsList">
                                <img src="../../assets/shop/shop2.jpg">
                                <div>锐澳 8度苹果西打风味鸡尾酒 500ml/罐</div>
                                <div><span style="font-size: 12px;">¥</span><span style="padding-left: 3px;">2.5</span>
                                </div>
                            </div>
                        </van-swipe-item>
                    </van-swipe>
                </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { WxGetPromotionPage, WxGetGoodsSortList } from "@/api/shop";
import { getOpenId } from "@/utils/auth";
import { GetCodeEntrysList } from "@/api/ResumeJS";
export default {
  data () {
    return {
      active: 0,
      listfrom: {}, //查询传参
      list: [],//内容
      typeList: [], //类型
    }
  },
  mounted () {
    this.getList("01");
    this.getTypeList();
  },
  methods: {
    // 点击进入商户详情
    detailClick (id) {
      var url = '/shop/PromotionXq/' + id
      this.$router.push(url);
    },
    // tabs选择
    typeClick (row) {
      console.log(row);
      this.list = {};
      this.getList(row)
    },
    getTypeList () {
      var opdeId = "oebDw5XNiVzsIUaP__HJXNUd6krs";
      GetCodeEntrysList({ kind: "310" }).then((res) => {
        this.typeList = res.data.data;
        for (let i = 0; i < this.typeList.length; i++) {
          if (this.typeList[i].Coding == "01") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/91b592c7-0c4.png"
          } else if (this.typeList[i].Coding == "02") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/26e26f0e-33e.png"
          } else if (this.typeList[i].Coding == "03") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/fd46f477-eff.png"
          } else if (this.typeList[i].Coding == "04") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/f2e4af73-093.png"
          } else if (this.typeList[i].Coding == "05") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/a61201d8-dc7.png"
          } else if (this.typeList[i].Coding == "06") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/7c15d48f-7aa.png"
          } else if (this.typeList[i].Coding == "07") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/71f7de50-bc2.png"
          } else if (this.typeList[i].Coding == "08") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/a31a7675-343.png"
          } else if (this.typeList[i].Coding == "09") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/bc192941-0f8.png"
          } else if (this.typeList[i].Coding == "10") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/4fa6367a-9a9.png"
          } else if (this.typeList[i].Coding == "11") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/e49a7b0b-688.png"
          } else if (this.typeList[i].Coding == "12") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/faca419d-dd3.png"
          } else if (this.typeList[i].Coding == "13") {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/2d77959c-3bc.png"
          } else {
            this.typeList[i].img = "https://lyxnyapi.dexian.ren//upload/images/2022/7/39457095-1ec.png"
          }
        }
      });
    },
    // 获取商铺列表
    getList (row) {
      this.listfrom.openID = getOpenId();
      this.listfrom.shopType = row;
      // this.listfrom.openID = "oebDw5XNiVzsIUaP__HJXNUd6krs";
      WxGetPromotionPage(this.listfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              for (var i = 0; i < this.list.length; i++) {
                if (this.list[i].ShopImg == "") {
                  this.list[i].ShopImgUrl =
                    "https://lyxnyapi.dexian.ren//upload/images/2022/7/b776c8ca-b1f.png";
                }
                if (this.list[i].StartDate != "") {
                  this.list[i].StartDate = this.list[i].StartDate.substring(0, 10);
                }
                if (this.list[i].EndDate != "") {
                  this.list[i].EndDate = this.list[i].EndDate.substring(0, 10);
                }
              }
            }
          }
        })
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
}
</script>
<style scoped>
.box {
  background: #f5f5f5;
}

/deep/.van-tabs__wrap {
  height: 85px;
}

.tabTitle {
  height: 65px;
}

.tabTitle img {
  width: 35px;
  height: 35px;
  margin: 3px 15px;
  border-radius: 50px;
}

.tabTitle div {
  font-size: 12px;
  text-align: center;
}

.lists {
  display: inline-block;
  float: left;
  width: 95%;
  margin: 0px 2.5%;
  overflow-y: scroll;
}

.lists .listBox {
}

.lists .list {
  display: flex;
  position: relative;
  margin: 10px 0px;
  background: #fff;
  border-radius: 8px;
}

.lists .list img {
  width: 85px;
  height: 85px;
  border-radius: 25px;
  padding: 8px;
}

.lists .list .listRight {
}

.list .listRight div:nth-child(1) {
  float: left;
  font-size: 14px;
  font-weight: 700;
  margin: 5px 5px;
  width: 95%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.list .listRight div:nth-child(2) {
  /* margin: 0px 5px;
    position: absolute;
    bottom: 3px;
    width: 55%; */
}

.list .listRight .addcar {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #ffca36;
  border-radius: 5px;
  text-align: center;
  line-height: 20px;
  font-size: 20px;
  position: absolute;
  right: 0px;
}

.shopbox {
  width: 95%;
  margin: 10px 2.5%;
  padding-bottom: 15px;
}

.shopbox .goodsList img {
  width: 95px;
  height: 70px;
  border-radius: 8px;
}

.shopbox .goodsList div:nth-child(3) {
  color: #ff4a26;
  margin-top: 3px;
}

.shopbox .goodsList div:nth-child(2) {
  font-size: 12px;
  width: 95px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

/deep/.shopbox .van-swipe__indicators {
  display: none;
}

.list .listRight .titleC {
  float: left;
  display: flex;
  align-items: center;
  font-size: 12px;
  margin: 3px 0px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
}

.list .listRight .titleC span {
}

.list .listRight .titleC img {
  width: 15px;
  height: 15px;
  padding: 0px 5px;
  margin: 0px;
}
</style>